let timerId: any;
const timeout = 300;

export const queryDebounce = (query: any, search: (arg0: any) => void) => {
  if (timerId) {
    clearTimeout(timerId);
  }
  // need this setTimeout for debounce purpose
  timerId = setTimeout(() => search(query), timeout);
};
